import React, { useCallback, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Drawer, List, Divider, Badge, Spin } from "antd"
import Text from "antd/es/typography/Text"
import { isEmpty } from "lodash"
import { DATE_FORMAT } from "../../common/config"
import moment from "moment"
import { useDiaryDrawer } from "../../hooks/useDiaryDrawer"
import { TagsList } from "../tags/TagsList"
import { getCompaniesSelector } from "../../store/selectors/companies/get-companies-selector"
// import { WorkspaceSelector } from "../../store/selectors/workspaces/get-workspace-list"
import { getCompaniesTh } from "../../store/actions/companies/get-companies"
// import DiaryNote from "./diary_note"


import {
  getBadgeIcon,
  getBadgeStyle,
  getDiaryBorderStyle,
} from "../../common/helpers"

export const DiaryDrawer = ({
  onClose,
  open,
  activities,
  activities_is_pending = false,
  diaryContent,
  diaryHtml,
  setDiaryHtml,
  setDiaryContent

}) => {
  const { renderContent, renderDiaryContent, date, allColors, showAddedDate, subType } =
    useDiaryDrawer({ diaryContent, diaryHtml, setDiaryHtml, setDiaryContent })
  const companies = useSelector(getCompaniesSelector)
  // const { workspaces } = useSelector(WorkspaceSelector)
  const dispatch = useDispatch()

  const isToday = (dateString) => {
    const today = new Date();
    const givenDate = new Date(dateString);

    return today.getFullYear() === givenDate.getFullYear() &&
      today.getMonth() === givenDate.getMonth() &&
      today.getDate() === givenDate.getDate();
  }

  useEffect(() => {
    if (isEmpty(companies)) {
      dispatch(getCompaniesTh())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLarge = useCallback((activity, key) => {
    if (key === "communication" || key === "diary") {
      const body = key === "communication" ? activity.body : activity.body_txt
      return body?.length > 100
    } else if (key === "note") {
      return false
    }
  }, [])
  const renderTagsWithDate = ({ activity, keys, title }) => {
    return (
      <div
        style={{
          marginLeft: "auto",
          display: "flex",
        }}
      >
        {!isEmpty(activity.tags) && !isLarge(activity, keys) && (
          <>
            <div
              className="diary_tags"
              style={{
                marginLeft: "16px",
                maxWidth: "100px",
              }}
            >
              <TagsList tags={activity.tags} allColors={allColors} />
            </div>
          </>
        )}
        {!(title === "Communication") && (
          <>
            {showAddedDate(keys, activity) && (
              <Text className="diary_drawer_date">
                Added: {moment(activity.create_dt).format(DATE_FORMAT)}
              </Text>
            )}
          </>
        )}
      </div>
    )
  }
  return (
    <Drawer
      className="diary_drawer"
      width={600}
      title={
        <div style={{ textAlign: "center" }}>
          <Text>{moment(date).format(DATE_FORMAT)}</Text>
        </div>
      }
      placement="right"
      closable={true}
      onClose={onClose}
      autoFocus
      open={open}
    >
      {activities_is_pending && <div className="loader_mask" > <Spin size="large" /></div>}
      {(diaryContent || diaryHtml || isToday(date)) && renderDiaryContent()}

      <div
        style={{
          height: "100%",
          overflowY: "auto",
        }}
      >
        {Object.keys(activities)
          .filter(
            (key) =>
              !isEmpty(activities[key]) &&
              key !== "files" &&
              key !== "link" &&
              key !== "workspace"
          )
          .map((key) => {
            const count = activities[key].length
            const title = key.charAt(0).toUpperCase() + key.slice(1)
            return (
              <div key={key} className="diary-activities-container">
                <div
                  style={{ justifyContent: "space-between", width: "100%" }}
                  className="title"
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {getBadgeIcon(title)}
                    <span
                      style={{
                        fontWeight: "500",
                        marginLeft: "3px",
                        marginRight: "6px",
                      }}
                    >
                      {title === "Communication"
                        ? "Conversations" : title === "Todo_done"
                          ? "Task Done" : title === "Todo_due"
                            ? "Task Due" : (title === 'Job' && subType === 'CRM') ? "Lead" : title}
                    </span>
                    <Badge count={count} style={getBadgeStyle(title)} />
                  </div>
                </div>

                <List
                  className="diary-list"
                  key={key}
                  style={getDiaryBorderStyle(title)}
                  rowKey={(row) => row.id}
                  size="large"
                  bordered
                  footer={null}
                  dataSource={activities[key]}
                  renderItem={(activity) => {
                    return (
                      <List.Item
                        style={{ display: "block", borderBottom: "0px" }}
                        className="position-relative"
                      >
                        <div
                          style={{
                            display: "flex",
                            padding: "9px 14px",
                            minHeight: "40px",
                          }}
                        >
                          <div
                            className="diary_details_text"
                            style={{
                              overflowX: "hidden",
                              width: "100%",
                            }}
                          >
                            {renderContent(
                              activity,
                              key,
                              renderTagsWithDate,
                              title,
                              allColors
                            )}
                          </div>
                        </div>
                        <Divider style={{ margin: "0" }} />
                      </List.Item>
                    )
                  }
                  }
                />



              </div>
            )
          })}
      </div>
    </Drawer>
  )
}
