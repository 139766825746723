import React, { Fragment, useCallback, useEffect, useState } from "react"
import withMemo from "../store/HOC/with_memo"
import { Button, Typography } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import { NoteEditor, TimeLine } from "../components"
import { useDispatch, useSelector } from "react-redux"
import { getDiariesSelector } from "../store/selectors"
import { getDiaries } from "../store/actions"
import { createDiaryActivity } from "../store/actions/diaries/create-diary"
import { removeDiaryTH } from "../store/actions/diaries/delete-diary"
import { getSpecificNote } from "../store/selectors/notes/get_notes_selector"
import { updateDiary } from "../store/actions/diaries/edit-diary"
import { useAction } from "../hooks/useAction"
import { getTodayDiary } from "../store/actions/diaries/get-diaries"
import { getWorkspaceList } from "../store/actions/workspaces/getWorkspaces"

export const TimelineContainer = withMemo(() => {
  const diaries = useSelector(getDiariesSelector)
  const [showEditor, setShowEditor] = useState(false)
  const dispatch = useDispatch()
  const specificDiary = useSelector(getSpecificNote)
  const getToday = useAction(getTodayDiary)
  useEffect(() => {
    dispatch(getDiaries())
    dispatch(getWorkspaceList())
    //eslint-disable-next-line
  }, [])
  const handleOpenEditor = useCallback(
    (e) => {
      getToday(() => {
        setShowEditor(true)
      })
    },
    [getToday]
  )
  const handleCloseEditor = useCallback(() => {
    setShowEditor(false)
  }, [])
  const handleAddNote = useCallback(
    (data, _data) => {
      const form = data || _data
      !form.company_id && delete form.company_id
      !form.workspace_id && delete form.workspace_id
      dispatch(createDiaryActivity(form))
      handleCloseEditor()
    },
    [dispatch, handleCloseEditor]
  )
  const handleEditDiary = useCallback(
    (_, _data) => {
      !_data.company_id && delete _data.company_id
      !_data.workspace_id && delete _data.workspace_id
      dispatch(updateDiary(_data))
      handleCloseEditor()
    },
    [dispatch, handleCloseEditor]
  )
  const handleDeleteNote = useCallback(() => {
    dispatch(removeDiaryTH(specificDiary.id))
    handleCloseEditor()
  }, [dispatch, handleCloseEditor, specificDiary.id])
  return (
    <Fragment >
      <div
        style={{
          display: "flex",
          marginBottom: "12px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.Title className="desktop-title" level={4}>
          Diary
        </Typography.Title>
        <Button
          type="primary"
          size={"small"}
          data-type={1}
          onClick={handleOpenEditor}
        >
          {<PlusOutlined />} New
        </Button>
      </div>
      <TimeLine items={diaries} handleOpenEditor={handleOpenEditor} showEditor={showEditor} />
      {showEditor && (
        <NoteEditor
          visible={showEditor}
          onClose={handleCloseEditor}
          handleEditNote={handleEditDiary}
          handleDeleteNote={handleDeleteNote}
          handleAddNote={handleAddNote}
          is_diary
        />
      )}
    </Fragment>
  )
})
